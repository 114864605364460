
import React from "react"
import { useRef } from "react"
import Helmet from "react-helmet"
import Layout from "../components/main/layout";
import Seo from "../components/main/seo";
// import { StaticQuery, graphql } from 'gatsby';
import { graphql, Link, withPrefix } from 'gatsby';
import { translaterUtil } from "../helpers/translateUtils"
import LegalsNav from "../helpers/animations/legals-nav";
import IncludeGTM from "../helpers/gtm";
import DgoClientDataCookie from "../helpers/dgo-cookie";


const Compliance = ({ pageContext, data }) => {
  IncludeGTM()
  DgoClientDataCookie()

  // NAV

  const legalsRef = useRef()
  
  LegalsNav(legalsRef)
  
  
  
  // ! UTILS

  const translation = (e) => translaterUtil(pageContext.lang, pageContext.template)[e]

  
  // ! STRAPI

  const $alternatePages = data.allSitePage.nodes.filter(e => e.pageContext.template == pageContext.template);

  const $metadata = data.strapiMetadata;
  const $mainNav = data.strapiMainNavigation;
  const $loginNav = data.strapiLoginNavigation;
  const $footerData = data.strapiFooterNavigation;
  const $socialsData = data.strapiFooterNavigation.Socials.socials_list;

  /* Single type */

  const $complianceData = data.strapiCompliance;

  let legalNavArray = [];

  if( $complianceData.content?.data?.content ) {

    let split = $complianceData.content.data.content.split('<h2>')

    split.forEach( (text, i) => {
      if ( i !== 0) legalNavArray[i-1] = text.split('</h2>')[0]
    })

  }


  return (


    <Layout mainNav={$mainNav} loginNav={$loginNav} footerData={$footerData} isPreview={pageContext.preview} lang={pageContext.lang} alternatePages={$alternatePages}  socialData={$socialsData}>

      <Seo metadata={$metadata} pageMetadata={$complianceData.Metadata} isPreview={pageContext.preview}  alternatePages={$alternatePages} activeLang={pageContext.lang} />

      <Helmet>
        <link href={withPrefix('assets/styles/legals.inline.min.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('assets/styles/legals.min.css')} rel="stylesheet" type="text/css" />
      </Helmet>


      {/* ! HERO */}
      <div className="c-hero o-section">
        <div className="c-hero__deco" />
        <h1 className="c-hero__heading c-heading o-wrapper">
          {$complianceData.title}
        </h1>
      </div> {/* .c-hero */}
      <main>

        {$complianceData.content &&
          <div className="c-legals o-wrapper" ref={legalsRef}>
          
            <div className="c-legals__gsap-header" />
          
            {/* NAV */}

            <div className="c-legals__nav u-text">
              <div className="c-legals__select c-legals-select">
                <select id="legal-nav" name="legal-nav" className="c-legals-select__select js-legals__select">

                {
                  legalNavArray &&
                  legalNavArray.map((item, i) => {
                  return (

                      <option value={`heading-${i+1}`}>{item}</option>

                  )
                })}

                </select>
              </div> {/* .c-legals-select */}
              <ul className="c-legals-list">

                {
                legalNavArray &&
                legalNavArray.map((item, i) => {
                    return (

                      <li className="c-legals-list__item">
                        <a href={`#heading-${i+1}`} className={`c-legals-list__link js-legals__link ${i === 0 ? 'is-active' : ''}`}>{item}</a>
                      </li>

                  )
                })}

                


              </ul>
            </div> {/* .c-legals__nav */}


            {/* MAIN */}

            <div className="c-legals__main c-legals__content js-legals__content o-section u-text" dangerouslySetInnerHTML={{ __html: $complianceData.content.data.content }}></div> {/* .c-legals__main */}
          </div>
        }
      </main>

    </Layout>

  )
}



export const query = graphql`
  query CompliancePageQuery ($lang: String, $id: String)  {

  strapiMetadata (locale: {eq: $lang}) {
    title
    description
    avatar{
        url
    }
  },
  strapiMainNavigation (locale: {eq: $lang}) {
    links {
      title
      url
    }
  },
  strapiLoginNavigation (locale: {eq: $lang}) {
    links {
      url
      title
    }
  },
  strapiFooterNavigation (locale: {eq: $lang}) {
    Socials {
      title
      socials_list{
        slug
        link{
          title
          url
        }
        image{
          url
          width
          height
        }
      }
    }
    Disclaimer {
      links {
        url
        title
      }
    }
    Link_group {
      link {
        title
        url
      }
    }
  },
  strapiClosing (locale: {eq: $lang}) {
    title
    description
    Link{
      title
      url
    }
  },

  strapiCompliance (locale: {eq: $lang}, id: {eq: $id}) {
    title
    content{
      data{
        content
      }
    }
    Metadata{
      title
      description
    }
  },
  allSitePage {
    nodes {
      path
      pageContext
    }
  }

}
`

export default Compliance
