import { useLayoutEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";


export default function LegalsNav(legalsRef){
      
      function getHeight(selector){
            return legalsRef.current.querySelector(selector).offsetHeight;
      }
      let headerHeight = 0;
      let topOffset = 0;
      let headerHeightInterval = null;
      let startTrigger = `top ${topOffset}`

      // ! GSAP

      function legalsNavInit(){
            const ctx = gsap.context(() => {

                  const legals = {
                        content: document.querySelector('.js-legals__content'),
                        select: document.querySelector('.js-legals__select'),
                  }
    
    
                  // ! Add dynamic ID
                  legals.content.querySelectorAll('h2').forEach( ($el, i) => {
                  
                      $el.setAttribute('id', `heading-${i+1}`)
                  
                      // ! SCROLL TRIGGERS
                  
                      console.log('foreach',startTrigger)
                      ScrollTrigger.create({
                          trigger: $el,
                          start: startTrigger,
                          end: `bottom 75%`,
                          // markers: true,
                          onEnter: () => {
                              // console.log( `Enter: #heading-${i+1}` )
                  
                              // ! Select value
                              legals.select.value = `heading-${i+1}`;
                              
                              // ! List value
                              document.querySelector('.js-legals__link.is-active').classList.remove('is-active')
                              document.querySelector(`.js-legals__link[href="#heading-${i+1}"]`).classList.add('is-active')
                  
                  
                          },
                          onEnterBack: () => {
                              // console.log( `EnterBack: #heading-${i+1}` )
                              if( i !== 0 ){
                  
                                  // ! Select value
                                  legals.select.value = `heading-${i}`
                                  
                                  // ! Select value
                                  document.querySelector('.js-legals__link.is-active').classList.remove('is-active')
                                  document.querySelector(`.js-legals__link[href="#heading-${i}"]`).classList.add('is-active')
                              }
                          },
                      })
                  }) // foreach h2

    
                  // ! Select navigation
                  
                  legals.select.onchange = () => {
                        window.location.hash = `#${legals.select.value}`
                  }

            }, legalsRef); // <- IMPORTANT! Scopes selector text
            return () => ctx.revert(); // cleanup

      } // legalsNavInit

      // ! INIT ON HEIGHT VALUE
      
      useLayoutEffect(() => {

            headerHeight = getHeight('.c-legals__gsap-header')

            headerHeightInterval = setInterval(() => {
                  if ( headerHeight > 0 ) {
                        clearInterval(headerHeightInterval)
                        topOffset = headerHeight + 10;
                        if( !window.matchMedia('(min-width: 61.25rem)').matches ) {
                              topOffset += document.querySelector('.c-legals__nav').offsetHeight;
                        }
                        startTrigger = `top ${topOffset}`
                        return legalsNavInit()
                  }
                  headerHeight = getHeight('.c-legals__gsap-header')
            },100)

      }, []) // UseLayoutEffect
} // ProductsScrollTriggers